<template>
  <div>
    <div class="pb-3 pt-3">
      <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">Your Private Medical Insurance</h3>
      <b-row>
        <b-col xl="5" lg="5" md="8" sm="12">
          <b-form-group label="">
            <template v-slot:label>
              <div class="d-flex flex-row v-align-center justify-content-start">
                <div>
                  <span>What type of cover would you like?</span>
                  <div class="ml-4 d-inline-block" :id="`cover-type-detail`">
                    <b-iconstack font-scale="2">
                      <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                      <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                    </b-iconstack>
                  </div>
                </div>
                
              </div>
              <b-popover
                :target="`cover-type-detail`"
                triggers="click"
                custom-class="quote-popover"
              >
                <template #title></template>
                <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                <div class="p-3">
                  <p><strong>Budget (No Outpatients)</strong> – covers the cost of any treatment you need while you’re in hospital, as well as the cost of your stay.</p>
                  <p><strong>Limited OP (Mid Range)</strong> – covers treatment, as well as some diagnostic testing. This can include blood tests at private hospitals, consultations with specialists. There would normally be a set amount of cover/limit.</p>
                  <p><strong>Full OP (Fully Comprehensive)</strong> – covers all your treatment, including diagnostics, consultations that you undergo at private facilities.</p>
                </div>
              </b-popover>
            </template>
            <b-form-radio-group 
              class="rq-toggle d-flex"
              v-model="coverage"
              name="coverage"
              buttons
            >
              <b-form-radio
                class="flex-fill sm-font" 
                v-for="(item,i) in coverageOptions" 
                :disabled="askingMode == 'application'"
                :key="i" 
                :value="item.value" 
                v-bind:style="[item.value == coverage ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="What would you like your maximum excess to be?" v-slot="{ ariaDescribedby }">
            <b-form-select
              :disabled="askingMode == 'application'"
              v-model="excessLevel"
              :options="excessOptions"
              :aria-describedby="ariaDescribedby"
              name="excess"
              buttons
            ></b-form-select>
          </b-form-group>
          <custom-datepicker
            :disabled="askingMode == 'application'" 
            label="When would you like your cover to start?" 
            format="DD/MM/YYYY" 
            placeholder="DD/MM/YYYY"
            :state="validateState('coverStartDate')"
            v-validate="{ required: true }"
            name="coverStartDate"
            data-vv-as="cover start date"
            :veeError="veeErrors.first('coverStartDate')"
            v-model="coverStartDate" />
        </b-col>
      </b-row>

      <b-form-group class="mt-6" label="Do you currently have a private medical insurance?" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          class="rq-toggle"
          v-model="hasExistingPolicy"
          :aria-describedby="ariaDescribedby"
          buttons
        > 
          <b-form-radio
            class="w-100px" 
            v-for="(item,i) in ynOptions" 
            :key="i" 
            :disabled="askingMode == 'application'"
            :value="item.value" 
            v-bind:style="[item.value == hasExistingPolicy ? $store.getters.buttonBox : {}]"
          >{{item.text}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-row>
        <b-col xl="5" lg="5" md="8" sm="12">
          <div v-if="hasExistingPolicy">
            <b-form-group label="Years insured" v-slot="{ ariaDescribedby }">
              <b-form-select
                :disabled="askingMode == 'application'"
                v-model="yearsInsured"
                :options="yearsInsuredOptions"
                :aria-describedby="ariaDescribedby"
                buttons
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Years claim free" v-slot="{ ariaDescribedby }">
              <b-form-select
                :disabled="askingMode == 'application'"
                v-model="yearsClaimFree"
                :options="yearsClaimFreeOptions"
                :aria-describedby="ariaDescribedby"
                buttons
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Number of claims" v-slot="{ ariaDescribedby }">
              <b-form-select
                :disabled="askingMode == 'application'"
                v-model="numberOfClaims"
                :options="numberOfClaimsOptions"
                :aria-describedby="ariaDescribedby"
                buttons
              ></b-form-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    
    <div class="pb-3 pt-3">
      <b-row>
        <b-col xl="5" lg="5" md="8" sm="12">
          <h3 class="header-text" v-bind:style="$store.getters.writingColourBlockText">About You</h3>
          <div class="mb-4 mt-4">The information provided may be used by Insurers. If you do not proceed to application, we still need to record that you used our system. We may contact you to assist with getting your insurance in place.</div>

          <b-form-group label="Title" v-slot="{ ariaDescribedby }">
            <b-form-select
              v-model="title"
              :options="titleOptions"
              :aria-describedby="ariaDescribedby"
              name="title"
              buttons
            ></b-form-select>
          </b-form-group>

          <b-form-group label="First name">
            <b-form-input
              v-model="firstName"
              v-validate="{ required: true }"
              :state="validateState('firstName')"
              aria-describedby="firstName-feedback"
              data-vv-as="firstname"
              name="firstName"
              type="text"
            ></b-form-input>
            <b-form-invalid-feedback id="firstName-feedback">{{ veeErrors.first('firstName') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Surname">
            <b-form-input
              v-model="lastName"
              v-validate="{ required: true }"
              :state="validateState('lastName')"
              aria-describedby="lastName-feedback"
              data-vv-as="lastname"
              name="lastName"
              type="text"
            ></b-form-input>
            <b-form-invalid-feedback id="lastName-feedback">{{ veeErrors.first('lastName') }}</b-form-invalid-feedback>
          </b-form-group>

          <custom-datepicker 
            label="Date of birth" 
            format="DD/MM/YYYY" 
            placeholder="DD/MM/YYYY"
            :state="validateState('dateOfBirth')"
            v-validate="{ required: true }"
            name="dateOfBirth"
            data-vv-as="date of birth"
            :veeError="veeErrors.first('dateOfBirth')"
            v-model="dateOfBirth" 
            :disabled="askingMode == 'application'"
          />

          <b-form-group class="mt-6" label="Phone number">
            <b-form-input
              v-model="landlineNumber"
              aria-describedby="landlineNumber-feedback"
              data-vv-as="landlin number"
              name="telephoneNumber"
              type="text"
            ></b-form-input>
            <b-form-invalid-feedback id="landlineNumber-feedback">{{ veeErrors.first('landlineNumber') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="mt-6" label="Mobile number">
            <b-form-input
              v-model="telephoneNumber"
              v-validate="'required|uk-mobile'"
              :state="validateState('telephoneNumber')"
              aria-describedby="telephoneNumber-feedback"
              data-vv-as="mobile number"
              name="telephoneNumber"
              type="text"
            ></b-form-input>
            <b-form-invalid-feedback id="telephoneNumber-feedback">{{ veeErrors.first('telephoneNumber') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Email address">
            <b-form-input
              v-model="email"
              v-validate="'required|email'"
              :state="validateState('email')"
              aria-describedby="email-feedback"
              name="email"
              type="email"
            ></b-form-input>
            <b-form-invalid-feedback id="telephoneNumber-feedback">{{ veeErrors.first('email') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="In the last 12 months, have you smoked or used any nicotine replacement products?" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="rq-toggle"
              v-model="isSmoker"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
              <b-form-radio
                class="w-100px"
                v-for="(item,i) in ynOptions" 
                :key="i" 
                :disabled="askingMode == 'application'"
                :value="item.value" 
                v-bind:style="[item.value == isSmoker ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Postcode">
            <b-form-input
              v-model="postcode"
              :disabled="askingMode == 'application'"
              v-validate="'required'"
              :state="validateState('postcode')"
              aria-describedby="postcode-feedback"
              name="postcode"
              type="text"
            ></b-form-input>
            <b-form-invalid-feedback id="postcode-feedback">{{ veeErrors.first('postcode') }}</b-form-invalid-feedback>
          </b-form-group>

          <div v-if="askingMode == 'application'">
            <div class="mb-4"><b-button variant="primary" v-bind:style="$store.getters.buttonBox" @click="getPostcodeAddress">Find address</b-button> <span class="ml-6 enter-address" @click="openAddressBox">Enter address manually</span></div>
            
            <b-form-group v-if="showAddresses">
              <template v-slot:label> 
                <span class="font-weight-bold">Choose your address</span>
              </template>
              <b-form-select
                v-model="selectedAddress"
                :options="addresses"
              ></b-form-select>
            </b-form-group>
            
            <div v-if="enterAddressManually">
              <b-form-group label="Address 1">
                <b-form-input
                  v-model="address1"
                  v-validate="{ required: true }"
                  :state="validateState('address1')"
                  aria-describedby="address1-feedback"
                  data-vv-as="address1"
                  name="address1"
                  type="text"
                ></b-form-input>
                 <b-form-invalid-feedback id="address1-feedback">{{ veeErrors.first('address1') }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Address 2">
                <b-form-input
                  aria-describedby="address2-feedback"
                  v-model="address2"
                  name="address2"
                  type="text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="County">
                <b-form-input
                  v-model="county"
                  name="county"
                  type="text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Town">
                <b-form-input
                  v-model="town"
                  name="town"
                  type="text"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-col>
        <b-col></b-col>
      </b-row>
      
      
      <h3 class="mb-4 header-text" v-bind:style="$store.getters.writingColourBlockText">Details of other people to be covered by this policy</h3>

      <div v-for="(member, index) in dependentMembers" v-bind:key="index">
        <b-row>
          <b-col xl="5" lg="5" md="8" sm="12">
            <b-form-group label="Title" v-slot="{ ariaDescribedby }">
              <b-form-select
                v-model="member.title"
                :options="titleOptions"
                :aria-describedby="ariaDescribedby"
                name="title"
                buttons
              ></b-form-select>
            </b-form-group>

            <b-form-group label="First name">
              <b-form-input
                v-model="member.firstName"
                type="text"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Surname">
              <b-form-input
                v-model="member.lastName"
                type="text"
                required
              ></b-form-input>
            </b-form-group>

            <custom-datepicker 
              class="mb-3" 
              label="Date of birth" 
              format="DD/MM/YYYY" 
              placeholder="DD/MM/YYYY" 
              :state="validateState(`dateOfBirth-${index}`)"
              v-validate="{ required: true }"
              :name="`dateOfBirth-${index}`"
              data-vv-as="member date of birth"
              :veeError="veeErrors.first(`dateOfBirth-${index}`)"
              v-model="member.dateOfBirth" 
              :disabled="askingMode == 'application'"
            />

            <b-form-group class="mt-6" label="Relation to policy owner" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                class="mb-4 rq-toggle d-flex"
                v-model="member.relationship"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
                <b-form-radio
                  class="flex-fill"
                  v-for="(item,i) in relationshipOptions" 
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[item.value == member.relationship ? $store.getters.buttonBox : {}]"
                >{{item.text}}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group label="In the last 12 months, has this person smoked or used any nicotine replacement products?" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="rq-toggle"
              v-model="member.isSmoker"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
              <b-form-radio
                class="w-100px"
                v-for="(item,i) in ynOptions" 
                :key="i" 
                :disabled="askingMode == 'application'"
                :value="item.value" 
                v-bind:style="[item.value == member.isSmoker ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

            <div v-if="hasExistingPolicy">
              <b-form-group label="Years insured" v-slot="{ ariaDescribedby }">
                <b-form-select
                  :disabled="askingMode == 'application'"
                  v-model="member.yearsInsured"
                  :options="yearsInsuredOptions"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-select>
              </b-form-group>

              <b-form-group label="Years claim free" v-slot="{ ariaDescribedby }">
                <b-form-select
                  :disabled="askingMode == 'application'"
                  v-model="member.yearsClaimFree"
                  :options="yearsClaimFreeOptions"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-select>
              </b-form-group>

              <b-form-group label="Number of claims" v-slot="{ ariaDescribedby }">
                <b-form-select
                  :disabled="askingMode == 'application'"
                  v-model="member.numberOfClaims"
                  :options="numberOfClaimsOptions"
                  :aria-describedby="ariaDescribedby"
                  buttons
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="mb-4" v-if="askingMode == 'quote'">
              <b-icon icon="trash" class="rounded-circle" variant="danger" v-on:click="removeMember(index)" role="button" font-scale="2"></b-icon>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4" />
      </div>

      <b-button v-if="askingMode == 'quote'" class="mb-4" variant="primary" v-on:click="addMember" v-bind:style="$store.getters.buttonBox">Add Dependent</b-button>

      <b-row>
        <b-col xl="5" lg="5" md="8" sm="12">
          <b-form-group v-if="askingMode == 'application'" label="How long have you been registered with a UK GP as an NHS patient?" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="rq-toggle d-flex"
              v-model="gpRegistered"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
              <b-form-radio
                class="flex-fill"
                v-for="(item,i) in gpOptions" 
                :key="i" 
                :value="item.value" 
                v-bind:style="[item.value == gpRegistered ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group v-if="askingMode == 'quote'" label="Have you ever been diagnosed with any serious illness or injury that has required treatment from a medical professional?" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="rq-toggle"
              v-model="hasDiagnosedIllness"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
              <b-form-radio
                class="w-100px"
                v-for="(item,i) in ynOptions" 
                :key="i" 
                :value="item.value" 
                v-bind:style="[item.value == hasDiagnosedIllness ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group v-if="askingMode == 'quote'" label="Have you required any form of treatment, including medication, for any illness or injury in the last 5 years" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="rq-toggle"
              v-model="hasTreatment"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
              <b-form-radio
                class="w-100px"
                v-for="(item,i) in ynOptions" 
                :key="i" 
                :value="item.value" 
                v-bind:style="[item.value == hasTreatment ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group v-if="askingMode == 'quote'" label="In the last three years, have you or any other person to be insured: experienced symptoms, 
            or received any advice from a healthcare professional, or received treatment or have treatment planned or expected (including prescribed or 
            over the counter medication) for any condition?" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              class="rq-toggle"
              v-model="hasPreviousCheckup"
              :aria-describedby="ariaDescribedby"
              name="radios-btn-default"
              buttons
            >
              <b-form-radio
                class="w-100px"
                v-for="(item,i) in ynOptions" 
                :key="i" 
                :value="item.value" 
                v-bind:style="[item.value == hasPreviousCheckup ? $store.getters.buttonBox : {}]"
              >{{item.text}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div class="pb-3 pt-3">
      <b-row>
        <b-col xl="5" lg="5" md="8" sm="12">
          <div v-if="$store.getters.hasCustomerAgreement && askingMode == 'quote' && !$store.getters.twoPageQuote">
            <p>{{$store.getters.customerAgreeMessage || ""}}</p>
          </div>
          <b-button v-if="askingMode == 'quote'" class="large-button" variant="primary" v-on:click="getPmiQuotes" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.twoPageQuote ? "Next" : "Get Quote" }}</b-button>
          <b-button v-if="askingMode == 'application'" class="large-button" variant="primary" v-on:click="applyDetails" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.applyBtnText }}</b-button>
          <div v-if="askingMode == 'quote' && !$store.getters.twoPageQuote" class="mt-10">
            <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.privacyLink" :href="$store.getters.privacyLink" target="_blank" class="mr-5">Privacy Policy</a>
            <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.tosLink" :href="$store.getters.tosLink" target="_blank">Terms and Condition</a>
          </div>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
import CustomDatepicker from "@/component/CustomDatepicker.vue";
const moment = require('moment');

export default {
  name: "client-details",
  components: {
    CustomDatepicker
  },
  props: ['askingMode'],
  data() {
    return {
      isOtpWaiting: false,
      otpReference: "",
      enterAddressManually: true,
      showAddresses: true,
      coverageOptions: [
        { text: "Budget", value: "basic" },
        { text: "Mid Range", value: "intermediate" },
        { text: "Fully Comp", value: "full" }
      ],
      excessOptions: [
        { value: 0, text: '£0' },
        { value: 100, text: '£100' },
        { value: 150, text: '£150' },
        { value: 200, text: '£200' },
        { value: 250, text: '£250' },
        { value: 300, text: '£300' },
        { value: 500, text: '£500' },
        { value: 750, text: '£750' },
        { value: 1000, text: '£1000' },
        { value: 2000, text: '£2000' },
      ],
      titleOptions: [
        { text: "Mr", value: "mr" },
        { text: "Mrs", value: "mrs" },
        { text: "Miss", value: "miss" },
        { text: "Ms", value: "ms" },
        { text: "Master", value: "master" },
        { text: "Dr", value: "dr" },
      ],
      ynOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      relationshipOptions: [
        { text: "Spouse", value: "spouse" },
        { text: "Daughter", value: "daughter" },
        { text: "Son", value: "son" },
      ],
      yearsInsuredOptions: [
        { text: "1 year and less than 2 years", value: 2 },
        { text: "2 years and less than 3 years", value: 3 },
        { text: "3 year and less than 4 years", value: 4 },
        { text: "4 year and less than 5 years", value: 5 },
        { text: "5 years or more", value: 6 }
      ],
      yearsClaimFreeOptions: [
        { text: "Less than a year", value: 0 },
        { text: "1 year and less than 2 years", value: 1 },
        { text: "2 years and less than 3 years", value: 2 },
        { text: "3 year and less than 4 year", value: 3 },
        { text: "4 year and less than 5 years", value: 4  },
        { text: "5 years or more", value: 5 },
      ],
      numberOfClaimsOptions: [
        { text: "0", value: "0" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3+", value: "3" },
      ],
      gpOptions: [
        { text: "From birth", value: "From birth" },
        { text: "Up to 2 years", value: "Up to 2 years" },
        { text: "Up to 5 years", value: "Up to 5 years" },
        { text: "Over 5 years", value: "Over 5 years" },
      ]
    }
  },
  computed: {
    firstName: {
      get() {
        return this.quoteForm.primaryMember.firstName;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.firstName = value;
        this.updateDetails(quoteForm);
      }
    },
    lastName: {
      get() {
        return this.quoteForm.primaryMember.lastName;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.lastName = value;
        this.updateDetails(quoteForm);
      }
    },
    dateOfBirth: {
      get() {
        console.log("Fetch Birthday"+this.quoteForm.primaryMember.dateOfBirth);
        return this.quoteForm.primaryMember.dateOfBirth;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.dateOfBirth = value;
        console.log("BIRTHDAY: "+value);
        this.updateDetails(quoteForm);
      }
    },
    yearsInsured: {
      get() {
        return this.quoteForm.primaryMember.yearsInsured;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.yearsInsured = value;
        this.updateDetails(quoteForm);
      }
    },
    yearsClaimFree: {
      get() {
        return this.quoteForm.primaryMember.yearsClaimFree;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.yearsClaimFree = value;
        this.updateDetails(quoteForm);
      }
    },
    numberOfClaims: {
      get() {
        return this.quoteForm.primaryMember.numberOfClaims;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.numberOfClaims = value;
        this.updateDetails(quoteForm);
      }
    },
    telephoneNumber: {
      get() {
        return this.quoteForm.primaryMember.telephoneNumber;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.telephoneNumber = value;
        this.updateDetails(quoteForm);
      }
    },
    landlineNumber: {
      get() {
        return this.quoteForm.primaryMember.landlineNumber;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.landlineNumber = value;
        this.updateDetails(quoteForm);
      }
    },
    email: {
      get() {
        return this.quoteForm.primaryMember.email;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.email = value;
        this.updateDetails(quoteForm);
      }
    },
    postcode: {
      get() {
        return this.quoteForm.primaryMember.postcode;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.postcode = value;
        this.updateDetails(quoteForm);
      }
    },
    address1: {
      get() {
        return this.quoteForm.primaryMember.address1;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.address1 = value;
        this.updateDetails(quoteForm);
      }
    },
    address2: {
      get() {
        return this.quoteForm.primaryMember.address2;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.address2 = value;
        this.updateDetails(quoteForm);
      }
    },
    town: {
      get() {
        return this.quoteForm.primaryMember.town;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.town = value;
        this.updateDetails(quoteForm);
      }
    },
    county: {
      get() {
        return this.quoteForm.primaryMember.county;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.county = value;
        this.updateDetails(quoteForm);
      }
    },
    title: {
      get() {
        return this.quoteForm.primaryMember.title;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.title = value;
        this.updateDetails(quoteForm);
      }
    },
    dependentMembers: {
      get(){
        return this.quoteForm.dependentMembers.map(member => {
          member.isSmoker = member.isSmoker ? 1 : 0;
          return member;
        });
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.dependentMembers = value;
        this.updateDetails(quoteForm);
      }
    },
    isSmoker: {
      get(){
        return this.quoteForm.primaryMember.isSmoker ? 1 : 0;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.isSmoker = value;
        this.updateDetails(quoteForm);
      }
    },
    hasDiagnosedIllness: {
      get(){
        return this.quoteForm.hasDiagnosedIllness ? 1 : 0;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.hasDiagnosedIllness = value;
        this.updateDetails(quoteForm);
      }
    },
    hasTreatment: {
      get(){
        return this.quoteForm.hasTreatment ? 1 : 0;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.hasTreatment = value;
        this.updateDetails(quoteForm);
      }
    },
    hasPreviousCheckup: {
      get(){
        return this.quoteForm.hasPreviousCheckup ? 1 : 0;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.hasPreviousCheckup = value;
        this.updateDetails(quoteForm);
      }
    },
    gpRegistered: {
      get(){
        return this.quoteForm.gpRegistered;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.gpRegistered = value;
        this.updateDetails(quoteForm);
      }
    },
    coverage: {
      get() {
        return this.quoteForm.coverage;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.coverage = value;
        this.updateDetails(quoteForm);
      } 
    },
    excessLevel: {
      get() {
        return this.quoteForm.excessLevel;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.excessLevel = value;
        this.updateDetails(quoteForm);
      }
    },
    coverStartDate: {
      get() {
        return this.quoteForm.coverStartDate;
      },
      set(value) {
        let quoteForm = { ...this.quoteForm };
        quoteForm.coverStartDate = value;
        this.updateDetails(quoteForm);
      }
    },
    hasExistingPolicy: {
      get(){
        return (this.quoteForm.hasExistingPolicy ? 1 : 0);
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        if(value == 1){
          quoteForm.underwriting = "CPME";
        } else {
          quoteForm.underwriting = "NMORI";
        }
        quoteForm.hasExistingPolicy = value;
        this.updateDetails(quoteForm);
      }
    },
    addresses: {
      get(){
        var addresses = this.$store.state.pmi.addresses.map(address => {
          return Object.values(address).join(", ");
        });
        return addresses;
      }
    },
    selectedAddress: {
      get(){
        return this.$store.state.pmi.selectedAddress;
      },
      set(address){
        var x = address.split(',');
        var parsedX = {
          lineOne: x[0].trim(),
          lineTwo: x[1].trim(),
          lineThree: x[2].trim(),
          lineFour: x[3].trim(),
          locality: x[4].trim(),
          townCity: x[5].trim(),
          county: x[6].trim()
        }
        
        let quoteForm = { ...this.quoteForm };
        quoteForm.primaryMember.address1 = parsedX.lineOne;
        quoteForm.primaryMember.address2 = parsedX.lineTwo;
        quoteForm.primaryMember.town = parsedX.townCity;
        quoteForm.primaryMember.county = parsedX.county;

        this.updateDetails(quoteForm);
        this.$store.commit("updateSelectedAddress", address);
      }
    },
    quoteForm: {
      get() {
        return this.askingMode == "application" ? this.$store.state.pmi.applicationDetails : this.$store.state.pmi.quoteForm;
      }
    }
  },
  methods: {
    addMember: function(){
      this.$recordFrame();
      this.$store.commit("addMember");
      this.$resizeFrame();
    },
    removeMember: function(memberIndex){
      this.$store.commit("removeMember", memberIndex);
      this.$revertHeight();
    },
    updateDetails: function(data){
      this.askingMode == "application" ? this.$store.commit("updateApplicationDetails", data) : this.$store.commit("updateQuoteForm", data);
      this.$resizeFrame();
    },
    onContext: function(ctx) {
      this.formatted = ctx.selectedFormatted
      this.selected = ctx.selectedYMD
    },
    formatDate: function(value){
      return moment(value).format('DD/MM/YYYY');
    },
    validateState(ref) {
      console.log("VALIDATE")
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    applyDetails: function(){
      var _this = this;
      var delay = 0;

      if(!this.enterAddressManually){
        this.$resizeFrame();
        this.enterAddressManually = true;
        delay = 500;
      }

      setTimeout(function(){
        _this.$validator.validateAll().then(results => {
          console.log(results);
          if(results){
            _this.$store.commit("updateHasApplicationDetails", true);
            _this.$store.commit("updatePaymentStep","confirm");
            _this.$router.push({ name: 'PMIQuotePayment'});
          }
        });
      }, delay);
      
    },
    fetchQuotes: function(){
      this.$store.commit("updateLoadingStatus", true);
      this.$store.dispatch("getQuoteProducts").then(quotes => {
        var pluginMessage = { 
          messageType: "quoteResults",
          payload: {
            quoteReferenceId: this.$store.state.pmi.mainRqQuoteRef,
            clientDetails: this.$formatPmi(this.quoteForm),
            quoteResult: quotes
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        this.$router.push({ name: 'PMIQuoteResult' });
        this.$store.commit("updateLoadingStatus", false);
      })
      .catch(() => {
        this.$store.commit("updateLoadingStatus", false);
      });
    },
    fetchOtpQuotes: function(){
      this.$store.dispatch("getQuoteProducts").then(quotes => {
        var pluginMessage = { 
          messageType: "quoteResults",
          payload: {
            quoteReferenceId: this.$store.state.pmi.mainRqQuoteRef,
            clientDetails: this.$formatPmi(this.quoteForm),
            quoteResult: quotes
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        if(this.$route.name == 'PMIQuoteForm' && !this.isOtpWaiting){
          this.$store.commit("updateQuoteResult",[]);
        }
      })
      .catch(() => {
        this.$store.commit("updateLoadingStatus", false);
      });
    },
    getPmiQuotes: function(){
      var _this = this;
      this.$resetFrame();
      this.$validator.validateAll().then(result => {
        if(result){
          this.$store.commit("updateViewMode", false);
          this.$store.commit("updateEditMode", false);
          
          if(!this.$store.getters.twoPageQuote){
            // Check if form has text validation
            this.$store.dispatch('hasVerification', { phoneNumber: this.telephoneNumber }).then(res => {
              console.log(res);
              if(res.data.hasTextVerification){
                this.otpReference = res.data.reference;

                // Fetch quotes on background
                this.fetchOtpQuotes();

                this.$swal.fire({
                  position: 'top',
                  showClass: {
                    popup: 'swal2-show mt-25',
                  },
                  title: 'Verification',
                  html: `Enter the OTP code you have received.<br/><small>Code expires in 5 minutes.</small>`,
                  input: 'number',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Verify',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showDenyButton: true,
                  denyButtonText: 'Resend Code',
                  denyButtonColor: '#369A6E',
                  didOpen: () => {
                    this.isOtpWaiting = true;
                  },
                  preDeny: () => {
                    return new Promise(function(){
                      _this.$store.dispatch('hasVerification', { phoneNumber: _this.telephoneNumber, reference: _this.otpReference }).then(res => {
                        _this.$swal.enableButtons();
                        _this.otpReference = res.data.reference;
                      });
                    });
                  },
                  preConfirm: (otp) => {
                    return new Promise(function(resolve){
                      _this.$store.dispatch('verifyOtp', {
                        otp: otp,
                        reference: _this.otpReference
                      }).then(response => {
                        if(response.data.success){
                          resolve();
                          _this.$router.push({ name: 'PMIQuoteResult' });
                          _this.$store.commit("updateLoadingStatus", false);
                        } else {
                          _this.$swal.showValidationMessage("Invalid OTP code.");
                          _this.$swal.enableButtons();
                        }
                      });
                    });
                  }
                }).then((result) => {
                  if(result.dismiss == 'cancel'){
                    this.isOtpWaiting = false;
                    this.$store.commit("updateQuoteResult",[]);
                  }
                });
              } else {
                this.fetchQuotes();
              }
            }).catch(err => {
              console.log(err);
              if(this.$store.state.pmi.displayMode == 'demo'){
                this.fetchQuotes();
              }
            });
          } else {
            this.$router.push({ name: 'PMIQuoteResult' });
            this.$store.commit("updateLoadingStatus", false);
          }
          
        }
      });
    },
    getPostcodeAddress: function(){
      this.$store.dispatch("getPostcodeAddress").then(() => {
        this.enterAddressManually = true;
        this.showAddresses = true;

        this.$resizeFrame();
      });
    },
    openAddressBox: function(){
      this.enterAddressManually = true;
      this.$resizeFrame();
    }
  },
  mounted() {
    if(this.postcode){
      this.getPostcodeAddress();
    }
    this.$resizeFrame();
  },
  created(){
    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover')
    });
  }
}
</script>

<style lang="scss">
.quote-popover {
  min-width: 200px;

  .popover-body {
    padding: 0;
  }

  .popover-highlight {
    width: 100%; 
    height: 5px; 
    background-color: #FF0000; 
    border-radius: 5px 5px 0 0;
    margin-top: -1px;
  }
}

.enter-address {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .sm-font {
    font-size: 10px !important;
  }
}

.swal2-styled.swal2-deny {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 1.0625em;
}
</style>